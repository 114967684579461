<template>
 <div class="bg-gray-300">
        <div class="container mx-auto px-6 pt-12 lg:pt-20">
            
            <!-- Content -->
            <div class="flex">

                <!-- Left side dashboard -->
                <div class="relative hidden lg:block dashboard-profile h-auto">

                    <img src="@/assets/img/romb.png" alt="" class="absolute w-20 left-0 -top-6 ">
                    <img src="@/assets/img/square.png" alt="" class="absolute w-24 -left-6 top-72">

                    <div class="flex flex-col items-center">
                        <a href="#" class="flex items-center text-4xl font-bold mt-12">
                            <img src="@/assets/img/near_logo_stack1.png" alt="" class="">
                            <span class="w-0.5 h-11 bg-black ml-1 mr-2"></span>
                            Arts
                            <span class="w-2 h-2 rounded-full bg-black ml-1 -mt-5"></span>
                        </a>
                        <div class="mt-12 w-20 h-20 rounded-full dashboard-profile-img flex justify-center items-center">
                            <img src="@/assets/img/near_logo_stack2.png" alt="" class="">
                        </div>
                        <a class="text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-blue-400 text-xl font-bold mt-5">{{accountId}}</a>
                        <button @click="signOut" href="#" class="flex items-center mt-6 font-bold text-base hover:text-gray-500">
                            <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z" fill="black"/>
                            </svg>
                            Logout
                        </button>
                    </div>
                </div>

                <!-- Right side dashboard -->
                <div class="lg:ml-10 w-full">
                    <nav>
                        <!-- Mobile navigation -->
                        <div class="lg:hidden">
                            <div class="flex flex-col md:flex-row items-center md:justify-between">
                                <!-- Logo -->
                                <a href="#" class="flex items-center text-4xl font-bold ">
                                    <img src="@/assets/img/near_logo_stack1.png" alt="" class="">
                                    <span class="w-0.5 h-11 bg-black ml-1 mr-2"></span>
                                    Arts
                                    <span class="w-2 h-2 rounded-full bg-black ml-1 -mt-5"></span>
                                </a>

                                <div class="mt-4 flex justify-between items-center w-full md:w-auto">
                                    <!-- Account name -->
                                    <div class="flex items-center md:mr-5">
                                        <div class="w-10 h-10 md:w-16 md:h-16 p-2 rounded-full dashboard-profile-img flex justify-center items-center">
                                            <img src="@/assets/img/near_logo_stack2.png" alt="" class="">
                                        </div>
                                        <a class="ml-4 md:text-base font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-blue-400">{{accountId}}</a>
                                    </div>
                                    <!-- Logout btn -->
                                    <button @click="signOut" class="flex items-center font-bold text-sm hover:text-gray-500">
                                        <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z" fill="black"/>
                                        </svg>
                                        Logout
                                    </button>
                                </div>
                            </div>
                        </div>

                        <form  action="" class="mt-7 lg:mt-0 flex flex-col md:flex-row items-center justify-between w-full">
                            
                            <!-- Search -->
                            <div v-if="generatedDesign" class="w-full flex items-center">
                                <input type="text" class="placeholder-current text-gray-500 focus:text-black dashboard-search outline-none pl-16 py-4 font-bold"
                                 v-model="generatedDesign.seed">
                                <button @click="claimDesign(generatedDesign.seed)" href="#" class="ml-6 text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-900 to-blue-800 hover:from-blue-800 hover:to-blue-600 transform active:scale-95">Claim</button>
                            </div>

                            <!-- Generate new art -->
                            <button @click="generateDesign(accountId)" class="mt-7 md:mt-0 text-sm xl:text-base 2xl:text-lg generate-btn ml-auto bg-gradient-to-r from-purple-700 to-blue-400 rounded-full p-0.5 transform active:scale-95 duration-75">
                                <div class="bg-transparent hover:bg-gray-300 rounded-full duration-300">
                                    <div class="w-full h-full font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-white to-white hover:from-purple-700 hover:to-blue-400 py-4 duration-500">
                                        Generate new art
                                    </div>
                                </div>
                            </button>
                        </form>
                    </nav>

                    <div  v-if="generatedDesign!=null" class="relative mt-8 ownart-bg h-auto px-5 md:px-20 lg:px-16 xl:px-8 2xl:px-10 pt-9 pb-12">    
                     <div class="relative flex flex-col md:flex-row items-start md:items-end mt-9">

                        <img src="@/assets/img/romb.png" alt="" class="hidden md:block lg:hidden absolute w-16 top-0 right-24">
                        <img src="@/assets/img/black.png" alt="" class="hidden md:block absolute w-16 lg:w-24 -top-4 right-0">

                        <h1 class=" font-bold text-3xl 2xl:text-4xl md:pl-10">Your last generated art</h1>
                        <p class="float-left text-gray-500 mt-3 md:mt-0 md:ml-6 font-bold text-lg 2xl:text-2xl">#{{generatedDesign.seed}}</p>
                    </div>
                                <pre class="text-xs w-full p-8">{{generatedDesign.instructions}}</pre>
                    </div>

                    <div class="relative flex flex-col md:flex-row items-start md:items-end mt-9">

                        <img src="@/assets/img/romb.png" alt="" class="hidden md:block lg:hidden absolute w-16 top-0 right-24">
                        <img src="@/assets/img/black.png" alt="" class="hidden md:block absolute w-16 lg:w-24 -top-4 right-0">

                        <h1 class=" font-bold text-3xl 2xl:text-4xl md:pl-10">My art in own</h1>
                        <p class="float-left text-gray-500 mt-3 md:mt-0 md:ml-6 font-bold text-lg 2xl:text-2xl">here is your art</p>
                    </div>

                    <!-- Own art section -->
                    <div class="relative mt-8 ownart-bg h-auto px-5 md:px-20 lg:px-16 xl:px-8 2xl:px-10 pt-9 pb-12">

                        <img src="@/assets/img/square.png" alt="" class="hidden md:block lg:hidden w-24 absolute top-6 -left-12">
                        <img src="@/assets/img/vector.png" alt="" class="hidden md:block absolute w-24 -bottom-12 right-0">

                        <div v-if="myDesign">

                        <div class="flex items-center justify-center xl:justify-start">
                            <!-- <div class="flex ">
                                <h2 class="text-3xl 2xl:text-4xl font-bold">My art name</h2>
                                <span class="block w-3 h-3 rounded-full bg-black ml-2"></span>
                            </div> -->

                            <!-- Share seed btn -->
                            <!-- <a href="#" class="hidden xl:block text-xs 2xl:text-sm generate-btn xl:w-52 2xl:w-72 ml-auto bg-gradient-to-r from-blue-900 to-blue-700 rounded-full p-0.5 transform active:scale-95 duration-75">
                                <div class="bg-transparent hover:bg-gray-300 rounded-full duration-300">
                                    <div class="flex justify-center items-center w-full h-full font-bold text-white hover:text-blue-900 py-4 duration-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                          </svg>
                                        share seed number
                                    </div>
                                </div>
                            </a> -->

                            <!-- Burn art btn -->
                            <button @click="burnDesign" class="hidden xl:flex text-xs 2xl:text-sm ml-9  items-center justify-center xl:w-52 2xl:w-72 burn-shadow font-bold bg-white text-red-400 hover:bg-red-400 hover:text-white rounded-full py-3 transform active:scale-95 duration-75">
                                <svg class="mr-4" xmlns="http://www.w3.org/2000/svg" width="37" height="38" viewBox="0 0 37 38" fill="none">
                                    <path d="M22.3543 16.0802C22.3543 14.8669 23.9774 14.5453 24.408 15.6806C25.4496 18.4268 26.2085 20.8836 26.2085 22.2118C26.2085 26.4449 22.7574 29.8764 18.5002 29.8764C14.243 29.8764 10.7918 26.4449 10.7918 22.2118C10.7918 20.7848 11.6678 18.0552 12.8292 15.0634C14.3337 11.1879 15.0859 9.25014 16.0145 9.14576C16.3116 9.11237 16.6358 9.17241 16.9009 9.30996C17.7293 9.73981 17.7293 11.8533 17.7293 16.0802C17.7293 17.3501 18.7647 18.3796 20.0418 18.3796C21.319 18.3796 22.3543 17.3501 22.3543 16.0802Z" stroke="currentColor" stroke-width="2"/>
                                    <path d="M16.9582 29.8761L16.5595 28.885C15.8536 27.1304 16.1507 25.1313 17.3363 23.6577V23.6577C17.9341 22.9147 19.0656 22.9147 19.6634 23.6577V23.6577C20.849 25.1313 21.1461 27.1304 20.4402 28.885L20.0415 29.8761" stroke="currentColor" stroke-width="2"/>
                                </svg>
                                burn art
                            </button>

                        </div>

                        <div class="xl:hidden mt-8 flex flex-col md:flex-row items-center">

                            <div class="art-bg p-4 sm:p-8 md:p-4 w-full md:w-2/4 h-auto">
                                <img src="@/assets/img/art-img.png" alt="" class="w-full h-full">
                            </div>

                            <div class="flex flex-col space-y-8 ml-4 w-full md:w-2/4 mt-6 md:mt-0">
                                <!-- Share seed btn -->
                            <!-- <a href="#" class="text-xs 2xl:text-sm generate-btn w-full ml-auto bg-gradient-to-r from-blue-900 to-blue-700 rounded-full p-0.5 transform active:scale-95 duration-75">
                                <div class="bg-transparent hover:bg-gray-300 rounded-full duration-300">
                                    <div class="flex justify-center items-center w-full h-full font-bold text-white hover:text-blue-900 py-4 duration-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                                          </svg>
                                        share seed number
                                    </div>
                                </div>
                            </a> -->

                            <!-- Burn art btn -->
                            <button @click="burnDesign" class="text-xs 2xl:text-sm flex items-center justify-center w-full burn-shadow font-bold bg-white text-red-400 hover:bg-red-400 hover:text-white rounded-full py-3 transform active:scale-95 duration-75">
                                <svg class="mr-4" xmlns="http://www.w3.org/2000/svg" width="37" height="38" viewBox="0 0 37 38" fill="none">
                                    <path d="M22.3543 16.0802C22.3543 14.8669 23.9774 14.5453 24.408 15.6806C25.4496 18.4268 26.2085 20.8836 26.2085 22.2118C26.2085 26.4449 22.7574 29.8764 18.5002 29.8764C14.243 29.8764 10.7918 26.4449 10.7918 22.2118C10.7918 20.7848 11.6678 18.0552 12.8292 15.0634C14.3337 11.1879 15.0859 9.25014 16.0145 9.14576C16.3116 9.11237 16.6358 9.17241 16.9009 9.30996C17.7293 9.73981 17.7293 11.8533 17.7293 16.0802C17.7293 17.3501 18.7647 18.3796 20.0418 18.3796C21.319 18.3796 22.3543 17.3501 22.3543 16.0802Z" stroke="currentColor" stroke-width="2"/>
                                    <path d="M16.9582 29.8761L16.5595 28.885C15.8536 27.1304 16.1507 25.1313 17.3363 23.6577V23.6577C17.9341 22.9147 19.0656 22.9147 19.6634 23.6577V23.6577C20.849 25.1313 21.1461 27.1304 20.4402 28.885L20.0415 29.8761" stroke="currentColor" stroke-width="2"/>
                                </svg>
                                burn art
                            </button>
                            </div>
                        </div>

                        <div class="mt-12 flex items-center justify-between space-x-20">
                            <div class="bg-gray-800 editor rounded-xl w-full">
                                <div class="w-full h-8 bg-gray-900 rounded-t-xl">
                                    <p class="inline-block ml-12 align-middle pt-2 h-full px-4 bg-gray-700 text-gray-400 text-sm">about.#{{myDesign.seed}}</p>
                                    <div class="flex">
                                        <div class="grid grid-flow-row text-gray-500 text-sm m-3 md:m-4 gap-y-2">
                                            <div class="">
                                                <p>1</p>
                                            </div>
                                            <div class="">
                                                <p>2</p>
                                            </div>
                                            <div class="">
                                                <p>3</p>
                                            </div>
                                            <div class="">
                                                <p>4</p>
                                            </div>
                                            <div class="">
                                                <p>5</p>
                                            </div>
                                        </div>
                                        <div class="grid grid-flow-row text-gray-500 text-sm m-3 md:m-4">
                                            <div class="pb-1 ">
                                                <p class="text-green-400">.about <span class="text-gray-500">{</span></p>
                                            </div>
                                            <div class="py-1 border-l border-gray-600 pl-4 md:pl-8">
                                                <p class="text-gray-600">/* basic info */</p>
                                            </div>
                                            <div class="flex py-1 border-l border-gray-600 pl-4 md:pl-8">
                                                <p class="text-blue-200">seed<span class="text-gray-600">:</span></p>
                                                <p class="ml-4 text-purple-200">{{myDesign.seed}}<span class="text-gray-600">;</span></p>
                                            </div>
                                            <div class="flex py-1 border-l border-gray-600 pl-4 md:pl-8">
                                                <p class="text-blue-200">Owner<span class="text-gray-600">:</span></p>
                                                <p class="ml-4 text-purple-200">{{accountId}}<span class="text-gray-600 -ml-2">;</span></p>
                                            </div>
                                            <div class="pt-1 ">
                                                <p class="text-gray-500">}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="hidden xl:block">
                               <pre class="text-xs">{{myDesign.instructions}}</pre>
                            </div>
                        </div>
                        </div>

                        <div v-else>
                             <div class="flex items-center justify-center xl:justify-start">
                            <div class="flex ">
                                <h2 class="text-3xl 2xl:text-4xl font-bold">No arts yet... </h2>
                                <span class="block w-3 h-3  ml-2"> please claim</span>
                            </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Footer -->
            <footer class="mt-9 pb-12 md:mt-12 lg:mt-5 xl:mt-12">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-8">
                    <div class="pl-2 md:pl-24 flex flex-col space-y-2">
                        <a href="#" class="flex items-center text-4xl font-bold">
                            <img src="@/assets/img/near_logo_stack1.png" alt="" class="">
                            <span class="w-0.5 h-11 bg-black ml-1 mr-2"></span>
                            Arts
                            <span class="w-2 h-2 rounded-full bg-black ml-1 -mt-5"></span>
                        </a>
                        <a href="#" class="mt-2 font-semibold text-gray-500 hover:text-black ">
                            info@nearartgenerator
                        </a>
                        <a href="#" class="font-semibold text-gray-500 hover:text-black">
                            1-232-3434 (Main)
                        </a>
                    </div>
                    <div class="pl-2 md:pl-24">
                        <p class="text-xl font-bold">About</p>
                        <ul class="text-gray-500 font-semibold space-y-1 mt-2">
                            <li class=""><a href="" class="hover:text-black">About contract</a></li>
                            <li class=""><a href="" class="hover:text-black">About NEAR </a></li>
                            <li class=""><a href="" class="hover:text-black">About NEAR ecosystem</a></li>
                        </ul>
                    </div>
                    <div class="pl-2 md:pl-24">
                        <p class="text-xl font-bold">Community</p>
                        <ul class="text-gray-500 font-semibold space-y-1 mt-2">
                            <li class=""><a href="" class="hover:text-black">Community OS Wiki</a></li>
                            <li class=""><a href="" class="hover:text-black">Forum</a></li>
                            <li class=""><a href="" class="hover:text-black">Events</a></li>
                        </ul>
                    </div>
                    <div class="pl-2 md:pl-24">
                        <p class="text-xl font-bold">Help</p>
                        <ul class="text-gray-500 font-semibold space-y-1 mt-2">
                            <li class=""><a href="" class="hover:text-black">Support</a></li>
                            <li class=""><a href="" class="hover:text-black">info@nearanalytics</a></li>
                            <li class=""><a href="" class="hover:text-black">1-232-3434 (Main)</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    </div>
    <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="true"/>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '../store/store.js'
import router from '@/router/index.js'
import { useArtDemo } from "@/composables/near"
import { wallet} from "@/services/near";

export default {
    components:{
        Loading
    },
    setup() {
      const accountId  = store.state.accountId;
      const {generatedDesign,myDesign, generateDesign, claimDesign, burnDesign, isLoading} = useArtDemo();
      return {
      accountId,
      signOut: () => {
        wallet.signOut();
        localStorage.removeItem(`near-api-js:keystore:${accountId}:testnet`);
        router.push('/')
        },
      generateDesign,
      myDesign,
      generatedDesign,
      claimDesign,
      burnDesign,
      isLoading
    }
    }
}
</script>

<style>
.dashboard-art {
            height: 313px;
            width: 313px;
        }
</style>